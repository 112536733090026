import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>404 NOT FOUND</h1>
    <p>
      お探しのページが見つかりませんでした。。。
    </p>
    <a href='https://kinosato.org'>TOPへ戻る</a>
  </Layout>
)

export default NotFoundPage
